import React, { useEffect, useState } from 'react';
import { collection, getDocs, getFirestore, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';

const NotesFolders = ({ onSelectFolder }) => {
    const [folders, setFolders] = useState([]);
    const [newFolderName, setNewFolderName] = useState('');
    const [renameFolderId, setRenameFolderId] = useState('');
    const [renameFolderName, setRenameFolderName] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const db = getFirestore();
            const querySnapshot = await getDocs(collection(db, "folders"));
            const foldersData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                name: doc.data().name
            }));
            setFolders(foldersData);
        };

        fetchData();
    }, []);

    const handleCreateFolder = async () => {
        const db = getFirestore();
        const docRef = await addDoc(collection(db, 'folders'), { name: newFolderName });
        const newFolder = { id: docRef.id, name: newFolderName };
        setFolders(prevFolders => [...prevFolders, newFolder]);
        setNewFolderName('');
    };

    const handleDeleteFolder = async (folderId) => {
        const db = getFirestore();
        await deleteDoc(doc(db, 'folders', folderId));
        setFolders(prevFolders => prevFolders.filter(folder => folder.id !== folderId));
    };

    const handleRenameFolder = async () => {
        const db = getFirestore();
        await updateDoc(doc(db, 'folders', renameFolderId), { name: renameFolderName });
        setFolders(prevFolders =>
            prevFolders.map(folder => {
                if (folder.id === renameFolderId) {
                    return { ...folder, name: renameFolderName };
                }
                return folder;
            })
        );
        setRenameFolderId('');
        setRenameFolderName('');
    };

    

    return (
        <div>
            {folders.map((folder) => (
                <div key={folder.id} onClick={() => onSelectFolder(folder.id)}>
                    {folder.id === renameFolderId ? ( // Pokud se jedná o složku, která se právě přejmenovává
                        <>
                            <input
                                type="text"
                                value={renameFolderName}
                                onChange={(e) => setRenameFolderName(e.target.value)}
                            />
                            <button onClick={handleRenameFolder}>Save</button>
                            <button onClick={() => {
                                setRenameFolderId('');
                                setRenameFolderName('');
                            }}>Cancel</button>
                        </>
                    ) : (
                        <>
                            {folder.name}
                            <button onClick={() => handleDeleteFolder(folder.id)}>Delete</button>
                            <button onClick={() => {
                                setRenameFolderId(folder.id);
                                setRenameFolderName(folder.name);
                            }}>Rename</button>
                        </>
                    )}
                </div>
            ))}
            <div>
                <input
                    type="text"
                    value={newFolderName}
                    onChange={(e) => setNewFolderName(e.target.value)}
                />
                <button onClick={handleCreateFolder}>Create Folder</button>
            </div>
        </div>
    );
};

export default NotesFolders;
