import { FaHeadset, FaProjectDiagram, FaProductHunt, FaLaptopCode } from 'react-icons/fa';

const events = [
  {
    Icon: FaHeadset,
    year: '2016-2018',
    title: 'IT podpora',
    text: 'Jako IT support specialist jsem zodpovídal za správu uživatelských stanic, navrhování a instalaci zabezpečovacích systémů, a také za síťování kanceláří.'
  },
  {
    Icon: FaProjectDiagram,
    year: '2018-2020',
    title: 'IT Produktový vlastník a projektový manažer',
    text: 'V roli IT produktového vlastníka jsem vedl vývoj mobilní aplikace Carsharing, což mi umožnilo nahlédnout na produkt očima koncového uživatele. Jako projektový manažer jsem úspěšně zrealizoval automatizaci objednávkového systému pro GPS lokátory.'
  },
  {
    Icon: FaProductHunt,
    year: '2020-2023',
    title: 'Produktový manažer',
    text: 'Jako produktový manažer jsem měl na starosti Digital Signage software a tvorbu VPN sítě pro naše instalace. Věnoval jsem se také vývoji monitoringu služeb a podílel se na automatizaci výroby routerů a playerů.'
  },
  {
    Icon: FaLaptopCode,
    year: '2023-nyní',
    title: 'Freelancer s Technologickým Zaměřením',
    text: 'Jako freelancer se pohybuji v digitálním světě a uplatňuji své technické dovednosti na nejrůznější projekty. Díky novým úkolům rozšiřuji své znalosti a přináším inovativní řešení.'
  },
];

export default events;
