import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Login from './pages/Login';
import Notes from './pages/Notes';
import Messages from './pages/Messages';
import MyNavbar from './components/MyNavbar';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <MyNavbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/notes" element={<Notes />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/messages" element={<Messages />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
