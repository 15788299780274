import React, { useEffect, useState } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; 
import { onAuthStateChanged } from 'firebase/auth';
import "./MyNavbar.css";

function MyNavbar() {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser && currentUser.email === "p.jiri.maly@gmail.com") {
                // Uživatel je přihlášen a má správný email
                setUser(currentUser);
            } else {
                // Uživatel není přihlášen nebo nemá správný email
                setUser(null);
            }
        });

        return () => unsubscribe(); 
    }, []);

    const handleProfileRedirect = (path) => {
        navigate(path);
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
            <Container>
                <Navbar.Brand href="#home" onClick={(e) => { e.preventDefault(); scrollToTop(); navigate('/'); }}>jirimaly.com</Navbar.Brand>
                {/* <Navbar.Brand href="#home" onClick={(e) => { e.preventDefault(); navigate('/'); }}>jirimaly.com</Navbar.Brand> */}

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav>
                        {user && (
                            <>
                                {user && (
                                    <Nav>
                                        <Nav.Link onClick={() => handleProfileRedirect('/profile')}>Profil</Nav.Link>
                                        <Nav.Link onClick={() => handleProfileRedirect('/notes')}>Poznámky</Nav.Link>
                                        <Nav.Link onClick={() => handleProfileRedirect('/messages')}>Zprávy</Nav.Link>
                                        <img
                                            src={user.photoURL}
                                            alt="profil"
                                            style={{ width: "30px", height: "30px", objectFit: "cover", marginLeft: "8px", borderRadius: "50%" }}
                                        />
                                        <Nav.Link onClick={() => auth.signOut()}>Odhlásit se</Nav.Link>
                                    </Nav>
                                )}

                            </>
                        )}
      
                        {!user && (
                            <>
                                <Nav.Link onClick={() => scrollToSection('sluzby')}>Služby</Nav.Link>
                                <Nav.Link onClick={() => scrollToSection('o-me')}>O mě</Nav.Link>
                                <Nav.Link onClick={() => scrollToSection('kontakt')}>Kontakt</Nav.Link>
                            </>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default MyNavbar;
