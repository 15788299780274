import React, { useState } from 'react';
import NotesFolders from '../components/NotesFolders';
import NotesTitles from '../components/NotesTitles';
import NotesContent from '../components/NotesContent';
import { Container, Row, Col } from 'react-bootstrap';

const Notes = () => {
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedNoteId, setSelectedNoteId] = useState(null);
  const [userSelectedNote, setUserSelectedNote] = useState(false);

  const handleSelectFolder = (folder) => {
    setSelectedFolder(folder);
    setSelectedNoteId(null);
    setUserSelectedNote(false);
  };

  const handleSelectNote = (noteId) => {
    setSelectedNoteId(noteId);
    setUserSelectedNote(true);
  };

  const handleTitlesLoaded = (titles) => {
    if (!userSelectedNote && titles.length > 0) {
      setSelectedNoteId(titles[0].id);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <NotesFolders onSelectFolder={handleSelectFolder} />
        </Col>
        <Col>
          {selectedFolder && <NotesTitles
            folderId={selectedFolder}
            onSelectNote={handleSelectNote}
            onTitlesLoaded={handleTitlesLoaded}
          />}
        </Col>
        <Col>
          {selectedNoteId && <NotesContent noteId={selectedNoteId} />}
        </Col>
      </Row>
    </Container>
  );
};

export default Notes;
