import React, { useEffect, useState } from 'react';
import { Container, Card } from 'react-bootstrap';

const ReklamaBanner = ({ heading, subheading, description, buttonText, backgroundImage, altText, scrollToSectionId }) => {
  const [bannerHeight, setBannerHeight] = useState('auto');

  useEffect(() => {
    const navbarHeight = 90; // Nastavte skutečnou výšku vašeho Navbar
    const windowHeight = window.innerHeight;
    const calculatedHeight = windowHeight - navbarHeight;
    setBannerHeight(`${calculatedHeight}px`);
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
};

  return (
    <Container fluid className="p-4" style={{ backgroundColor: '#f8f9fa', height: bannerHeight }}>
      <Card className="text-center shadow-lg" style={{ height: '100%' }}>
        <Card.Img variant="top" src={backgroundImage} alt={altText} style={{ objectFit: 'cover', height: '50%' }}/>
        <Card.Body style={{ overflowY: 'auto' }}>
          <h1>{heading}</h1>
          <h2 className="text-muted">{subheading}</h2>
          <p>{description}</p>
          {/* Upraveno pro přidání scrolldown funkcionality */}
          <button className="btn btn-primary btn-lg mt-3 mb-3" onClick={() => scrollToSection('kontakt')}>{buttonText}</button>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default ReklamaBanner;
