import React from 'react';
import ReklamaBanner from '../components/ReklamaBanner';
import Title from '../components/Title';
import DlazdiceWrapper from '../components/Dlazdice';
import Timeline from '../components/Timeline';
import ContactForm from '../components/ContactForm';

const Home = () => {
  return (
    <>
      <ReklamaBanner
        heading="Oživte svůj byznys online! Získejte slevu na webové stránky a aplikace"
        subheading="20% sleva na webové stránky a aplikace"
        description="Od jednoduchých prezenčních webů po složité webové aplikace. Proměníme vaši vizi v realitu. Nechte nás posunout vaše podnikání na novou úroveň."
        buttonText="Kontaktujte mě a získejte slevu"
        backgroundImage={require('../img/ReklamaUnor24.webp')}
      />
      <section id="sluzby">
        <Title title="PROFESIONÁLNÍ IT SLUŽBY" subtitle="Zajišťuji komplexní IT služby, které pomůžou růstu vašeho podnikání" />
      </section>
      <DlazdiceWrapper />
      <section id="o-me">
        <Title title="O MNĚ" subtitle="Podívejte se na mé zkušenosti získané od počátku mé kariéry v IT podpoře až po vedení vlastních projektů" />
      </section>
      <Timeline />
      <section id="kontakt">
        <Title title="KONTAKT" subtitle="Napište mi a já se vám ozvu co nejdříve" />
      </section>
      <ContactForm />
    </>
  )
} 
export default Home