import React, { useState } from 'react';
import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://85.31.238.144:5000/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Zpracování odpovědi od serveru
        const responseData = await response.json();
        alert('Formulář byl úspěšně odeslán a e-mail byl zaslán.');
        // Resetování formuláře po úspěšném odeslání
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',
        });
      } else {
        throw new Error('Něco se pokazilo při odesílání e-mailu.');
      }
    } catch (error) {
      console.error("Při odesílání formuláře došlo k chybě: ", error);
      alert('Při odesílání formuláře došlo k chybě.');
    }
  };

  return (
    <Container className="mt-5">
      <Row className="mb-4">
      <Col md={6} className="d-flex align-items-stretch mb-3 mb-md-0"> 
    
          <Card className="w-100">
            <Card.Img variant="top" src={require("../img/ReklamaUnor24.webp")} alt="Popis" />
            <Card.Body>

            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <h2 className="mb-4">Kontaktní formulář</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Jméno"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="rounded-pill"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="rounded-pill"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="tel"
                placeholder="Telefon"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="rounded-pill"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                as="textarea"
                rows={6}
                placeholder="Vaše zpráva"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="rounded-3"
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="rounded-pill">
              Odeslat
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactForm;
