import React from 'react';
import { Card } from 'react-bootstrap';
import dlazdiceData from '../data/dlazdiceData';
import './Dlazdice.css';

const Dlazdice = ({ icon: Icon, title, text }) => {
    return (
        <Card className="dlazdice-card">
            <div className="icon-wrapper">
                <Icon />
            </div>
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>{text}</Card.Text>
            </Card.Body>
        </Card>
    );
}

const DlazdiceWrapper = () => {
    return (
        <div className="dlazdice-wrapper">
            {dlazdiceData.map((dlazdice, index) => (
                <Dlazdice key={index} {...dlazdice} />
            ))}
        </div>
    );
}

export default DlazdiceWrapper;
