import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; // Import Firebase auth
import { onAuthStateChanged, signOut } from 'firebase/auth';
import Title from '../components/Title';

const Profile = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        // Uživatel je přihlášen
        setUser(currentUser);
      } else {
        // Uživatel není přihlášen, přesměrování na /login
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogout = async () => {
    await signOut(auth); // Odhlásit uživatele
    navigate('/login'); // Přesměrovat na přihlašovací stránku
  };

  if (!user) {
    return null; // Nebo zobrazit indikátor načítání
  }

  return (
    <>
      <Title title="Profil" subtitle="" />
      <div className="container mt-5">
        <div className="text-center">
          {user.photoURL && (
            <img 
              src={user.photoURL} 
              alt="profilový obrázek" 
              className="rounded-circle" 
              style={{ width: "100px", height: "100px", objectFit: "cover" }} 
            />
          )}
          <h2>Vítejte, {user.displayName}</h2>
          <p>Email: {user.email}</p>
          <button className="btn btn-danger" onClick={handleLogout}>Odhlásit se</button>
        </div>
      </div>
    
    </>
  );
}

export default Profile;
