// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; 
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCjXh4Tl54zuh49BAV9PNff5UCdd_i-_AY",
  authDomain: "jirimaly-1dc01.firebaseapp.com",
  projectId: "jirimaly-1dc01",
  storageBucket: "jirimaly-1dc01.appspot.com",
  messagingSenderId: "409091367274",
  appId: "1:409091367274:web:a1b487d572872ea0694513",
  measurementId: "G-QW01CB0MWZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app); 
export const auth = getAuth(app);
export const db = getFirestore(app); 

export default app;
