import React, { useEffect, useState } from 'react';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';

const NotesContent = ({ noteId }) => {
    const [content, setContent] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [newContent, setNewContent] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            if (!noteId) return;
            const db = getFirestore();
            const noteRef = doc(db, "notes", noteId);
            const noteSnap = await getDoc(noteRef);

            if (noteSnap.exists()) {
                setContent(noteSnap.data().content);
            } else {
                setContent('Poznámka nebyla nalezena.');
            }
        };

        fetchData();
    }, [noteId]);

    const handleEdit = () => {
        setIsEditing(true);
        setNewContent(content);
    };

    const handleSave = async () => {
        const db = getFirestore();
        const noteRef = doc(db, "notes", noteId);
        await updateDoc(noteRef, { content: newContent });
        setContent(newContent);
        setIsEditing(false);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setNewContent(content);
    };

    return (
        <div>
            {isEditing ? (
                <div>
                    <textarea value={newContent} onChange={(e) => setNewContent(e.target.value)} />
                    <button onClick={handleSave}>Save</button>
                    <button onClick={handleCancel}>Cancel</button>
                </div>
            ) : (
                <div>
                    <div>{content}</div>
                    <button onClick={handleEdit}>Edit</button>
                </div>
            )}
        </div>
    );
};

export default NotesContent;
