import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, getFirestore, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { Button, Modal } from 'react-bootstrap';

const NotesTitles = ({ folderId, onSelectNote, onTitlesLoaded }) => {
    const [titles, setTitles] = useState([]);
    const [newNoteTitle, setNewNoteTitle] = useState('');
    const [renamingNoteId, setRenamingNoteId] = useState(null);
    const [renamingNoteTitle, setRenamingNoteTitle] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [noteToDelete, setNoteToDelete] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (!folderId) {
                setTitles([]);
                return;
            }
            const db = getFirestore();
            const notesQuery = query(collection(db, "notes"), where("folder", "==", folderId));
            const querySnapshot = await getDocs(notesQuery);
            const titlesData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                title: doc.data().title
            }));
            setTitles(titlesData);
            if (titlesData.length > 0) {
                onTitlesLoaded(titlesData);
            }
        };

        fetchData();
    }, [folderId, onTitlesLoaded]);

    const handleAddNote = async () => {
        if (newNoteTitle.trim() === '') {
            return;
        }
        const db = getFirestore();
        const newNote = {
            title: newNoteTitle,
            folder: folderId
        };
        await addDoc(collection(db, "notes"), newNote);
        setNewNoteTitle('');
        fetchData();
    };

    const handleDeleteNote = async () => {
        if (!noteToDelete) return;
        const db = getFirestore();
        await deleteDoc(doc(db, "notes", noteToDelete));
        setNoteToDelete(null);
        setShowDeleteModal(false);
        fetchData();
    };

    const handleRenameNote = async (noteId) => {
        if (renamingNoteTitle.trim() === '') {
            return;
        }
        const db = getFirestore();
        const noteRef = doc(db, "notes", noteId);
        await updateDoc(noteRef, { title: renamingNoteTitle });
        setRenamingNoteId(null);
        setRenamingNoteTitle('');
        fetchData();
    };

    const handleCancelRename = () => {
        setRenamingNoteId(null);
        setRenamingNoteTitle('');
    };

    const fetchData = async () => {
        if (!folderId) {
            setTitles([]);
            return;
        }
        const db = getFirestore();
        const notesQuery = query(collection(db, "notes"), where("folder", "==", folderId));
        const querySnapshot = await getDocs(notesQuery);
        const titlesData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            title: doc.data().title
        }));
        setTitles(titlesData);
        if (titlesData.length > 0) {
            onTitlesLoaded(titlesData);
        }
    };

    useEffect(() => {
        fetchData();
    }, [folderId, onTitlesLoaded]);

    return (
        <div>
            {titles.map((note) => (
                <div key={note.id} onClick={() => onSelectNote(note.id)}>
                    {renamingNoteId === note.id ? (
                        <div>
                            <input
                                type="text"
                                value={renamingNoteTitle}
                                onChange={(e) => setRenamingNoteTitle(e.target.value)}
                                autoFocus
                            />
                            <button onClick={() => handleRenameNote(note.id)}>Save</button>
                            <button onClick={handleCancelRename}>Cancel</button>
                        </div>
                    ) : (
                        <>
                            {note.title}
                            <Button variant="danger" onClick={() => {
                                setNoteToDelete(note.id);
                                setShowDeleteModal(true);
                            }}>Delete</Button>
                            <button onClick={() => {
                                setRenamingNoteId(note.id);
                                setRenamingNoteTitle(note.title);
                            }}>Rename</button>
                        </>
                    )}
                </div>
            ))}
            <input
                type="text"
                value={newNoteTitle}
                onChange={(e) => setNewNoteTitle(e.target.value)}
            />
            <button onClick={handleAddNote}>Add Note</button>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this note?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                    <Button variant="danger" onClick={handleDeleteNote}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default NotesTitles;
