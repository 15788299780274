import React from 'react';
import events from '../data/eventsData';
import { Card } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import './Timeline.css';

const TimelineItem = ({ Icon, year, title, text }) => {
  return (
    <div className="timeline-item d-flex flex-column flex-md-row align-items-md-center my-4">
      <div className="timeline-icon-container col-12 col-md-4 text-center mb-3 mb-md-0">
        <IconContext.Provider value={{ className: "timeline-icon", size: "100px" }}>
          {Icon && <Icon />}
        </IconContext.Provider>
      </div>
      <div className="timeline-content col-12 col-md-8">
        <Card className="timeline-card border-0 h-100">
          <Card.Body>
            <div className="timeline-year text-muted">{year}</div>
            <h2 className="timeline-title">{title}</h2>
            <p className="timeline-text">{text}</p>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}



const Timeline = () => {
  return (
    <div className="container my-5 timeline-container">
      <div className="row">
        {events.map((event, index) => (
          <TimelineItem 
            key={index}
            Icon={event.Icon}
            year={event.year}
            title={event.title}
            text={event.text}
          />
        ))}
      </div>
    </div>
  );
}

export default Timeline;
