import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase'; // Import Firebase auth

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        // Uživatel je již přihlášen, přesměrujeme ho na /profile
        navigate('/profile');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      // Po úspěšném přihlášení je uživatel automaticky přesměrován díky useEffect
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "70vh" }}>
      <h2 className="mb-4">Vítejte v naší aplikaci</h2>
      <p className="text-center mb-4">Pro pokračování se prosím přihlaste pomocí svého Google účtu.</p>
      <button className="btn btn-primary" onClick={handleLogin}>Přihlásit se pomocí Google</button>
    </div>
  );
};

export default Login;
