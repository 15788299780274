import React, { useEffect, useState } from 'react';
import { db } from '../firebase'; // Ujistěte se, že máte tento import správně nastavený
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { Container, ListGroup } from 'react-bootstrap';

const Messages = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "ContactForm"), orderBy("createdAt", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(messages);
    });

    return () => unsubscribe();
  }, []);

  return (
    <Container className="mt-5">
      <h2>Zprávy</h2>
      <ListGroup>
        {messages.map((message) => (
          <ListGroup.Item key={message.id}>
            <strong>Jméno:</strong> {message.name}<br />
            <strong>Email:</strong> {message.email}<br />
            <strong>Telefon:</strong> {message.phone}<br />
            <strong>Zpráva:</strong> {message.message}<br />
            <strong>Odesláno:</strong> {message.createdAt?.toDate().toLocaleString()}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  );
}

export default Messages;
