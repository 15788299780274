import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

const Footer = () => {
  const [gitCommitHash, setGitCommitHash] = useState('');

  useEffect(() => {
    // Načtení hashu commitu z veřejného souboru
    fetch('/version.txt')
      .then(response => response.text())
      .then(hash => {
        setGitCommitHash(hash);
      });
  }, []);

  return (
    <footer className="footer mt-auto py-4 bg-light" style={{ padding: '2rem 0' }}>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-auto">
            <div className="text-muted">Kontakt:</div>
            <div>
              <a href="tel:+420730662504" className="link-dark text-decoration-none">+ 420 730 662 504</a>
            </div>
            <div>
              <a href="mailto:mail@jirimaly.com" className="link-dark text-decoration-none">mail@jirimaly.com</a>
            </div>
          </div>
          <div className="col-auto">
            <a href="https://www.linkedin.com/in/jiri-maly-fl/" target="_blank" rel="noopener noreferrer" className="text-decoration-none">
              <FontAwesomeIcon icon={faLinkedin} size="3x" className="text-primary" />
            </a>
          </div>
          <div className="col-auto">
            <span className="text-muted">Copyright </span>
            <Link to="/profile" className="text-muted text-decoration-none non-clickable-link">©</Link>
            <span className="text-muted"> Jiří Malý</span>
            {/* Zobrazení hashu posledního commitu */}
            <div className="text-muted">Verze commitu: {gitCommitHash.substring(0, 7)}</div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
