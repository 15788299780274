// Import ikon z react-icons
import { FaServer, FaProjectDiagram, FaCode, FaUserCog } from 'react-icons/fa';

const dlazdiceData = [
    {
      icon: FaUserCog, // Ikonka pro Konzultace a Komplexní IT Řešení
      title: "Konzultace a Komplexní IT Řešení",
      text: "Poskytuji komplexní konzultační služby v oblasti IT infrastruktury a bezpečnosti. Specializuji se na strategické plánování a návrhy pro optimalizaci procesů a síťové architektury zlepšující IT prostředí firem."
    },
    {
      icon: FaServer, // Ikonka pro Linuxovou a Síťovou Administraci
      title: "Linuxová a Síťová Administrace",
      text: "Nabízím služby v oblasti správy Linuxových systémů a síťových technologií. Mojí doménou je vytváření robustních a bezpečných systémových a síťových řešení, které podporují podnikovou infrastrukturu a zvyšují její výkonnost."
    },
    {
        icon: FaProjectDiagram, // Ikonka pro Projektové Řízení a Koordinaci
        title: "Projektové Řízení a Koordinace",
        text: "Koordinuji projekty a vedu týmy za účelem dosažení efektivní spolupráce na složitých IT projektech. S důrazem na přesné cíle a termíny zajišťuji hladký průběh projektů od počátečního návrhu po skončení realizace."
      },
      {
        icon: FaCode, // Ikonka pro Vývoj a Automatizaci IT Řešení
        title: "Vývoj a Automatizace IT Řešení",
        text: "Jako softwarový vývojář zastřešuji široké spektrum činností. Tvořím responsivní front-end řešení a robustní back-end architekturu, které společně zajišťují nejen efektivní a hladký běh aplikací, ale i jejich automatizovanou správu a údržbu."
      },
];

export default dlazdiceData;
