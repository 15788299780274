import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Title = ({ title, subtitle }) => {
  return (
    <Container as="section" fluid className="d-flex align-items-center justify-content-center py-5" style={{ minHeight: '40vh', background: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)' }}>
      <Row className="justify-content-center text-center">
        <Col xs={12}>
          <h1 style={{ color: '#007bff', marginBottom: '0.5rem' }}>{title}</h1>
          <div style={{ width: '60px', height: '2px', backgroundColor: 'rgb(0 123 255 / 43%', margin: '0 auto 1.5rem' }}></div>
          <p className="lead" style={{ color: '#6c757d' }}>{subtitle}</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Title;
